export default class ContrachequeService {
  constructor(http) {
    this._http = http
  }

  async getListaContrachequeDoServidor(cpf, matricula) {
    const { data } = await this._http.get(`/api/contracheque/listar`, {
      params: { cpf: cpf, matricula: matricula },
    })
    return data
  }

  async obterContracheque(id, cpf, matricula) {
    const { data } = await this._http.get(`/api/contracheque/detalhe/${id}`, {
      params: { cpf: cpf, matricula: matricula },
    })
    return data
  }

  async getListaInformesRendimentoDoServidor(cpf, matricula) {
    const { data } = await this._http.get(
      `/api/contracheque/informes_de_rendimento/${cpf}`,
      {
        params: { matricula: matricula },
      },
    )

    return data
  }

  async downloadInformeRendimento(filename, cpf, matricula) {
    const { data } = await this._http.get(
      `/api/contracheque/informes_de_rendimento/download/${filename}`,
      {
        params: { cpf: cpf, matricula: matricula },
      },
    )

    return data
  }

  async validarContracheque(map) {
    const { data } = await this._http.get(
      `/api/contracheque/validarContracheque?codigo=${map.get(
        'codigo',
      )}&cpf=${map.get('cpf')}&bruto=${map.get('bruto')}`,
    )

    return data
  }
}
