<template>
  <BlockUI :blocked="showLoading">
    <Card class="contracheque">
      <template #content>
        <div class="contanier">
          <div class="row">
            <div class="col">
              <table id="header">
                <thead>
                  <tr id="title">
                    <th colspan="1">
                      <img :src="imagemSrc" />
                    </th>
                    <th colspan="4">
                      <label>{{ contracheque.orgao }}</label
                      ><br />
                      <span>{{ contracheque.cnpj }}</span>
                    </th>
                    <th colspan="3">
                      <label>Comprovante de Pagamento</label>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 40%" colspan="2">
                      <label>Lotação</label><br />
                      <span>{{ contracheque.lotacao }}</span>
                    </th>
                    <th style="width: 40%" colspan="3">
                      <label>Unidade de Trabalho</label><br />
                      <span>{{ contracheque.unidadeDeTrabalho }}</span>
                    </th>
                    <th style="width: 20%" colspan="3">
                      <label>Período</label><br />
                      <span>{{ contracheque.periodo }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 50%" colspan="4">
                      <label>Servidor</label><br />
                      <span>{{ contracheque.servidor }}</span>
                    </th>
                    <th style="width: 20%" colspan="2">
                      <label>Matrícula</label><br />
                      <span>{{ contracheque.matricula }}</span>
                    </th>
                    <th style="width: 30%" colspan="2">
                      <label>CPF</label><br />
                      <span>{{ contracheque.cpf }}</span>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 50%" colspan="3">
                      <label>Cargo</label><br />
                      <span>{{ contracheque.cargo }}</span>
                    </th>
                    <th style="width: 30%" colspan="3">
                      <label>Unidade Pagadora</label><br />
                      <span>{{ contracheque.unidadePagadora }}</span>
                    </th>
                    <th style="width: 20%" colspan="2">
                      <label>Regime</label><br />
                      <span>{{ contracheque.regime }}</span>
                    </th>
                  </tr>
                  <th colspan="1">
                    <label>T. S. Estado</label><br />
                    <span>{{ contracheque.tempoEstado }}</span>
                  </th>

                  <th colspan="1">
                    <label>T. S. Quinquênio</label><br />
                    <span>{{ contracheque.tempoQuinquenio }}</span>
                  </th>

                  <th colspan="1">
                    <label>T. S. Aposentado</label><br />
                    <span>{{ contracheque.tempoAposentadoria }}</span>
                  </th>

                  <th colspan="1">
                    <label>T. S. Adquirido</label><br />
                    <span>{{ contracheque.tempoAdquirido }}</span>
                  </th>
                  <th colspan="1">
                    <label>CLF</label><br />
                    <span>{{ contracheque.tempoAdquirido }}</span>
                  </th>
                  <th
                    style="
                      width: 40%;
                      border-left: 1px solid black;
                      padding-left: 10px;
                      font-weight: bold;
                    "
                    colspan="3">
                    <label>Código Certificador</label><br />
                    <span>{{ contracheque.codigoCertificador }}</span>
                  </th>
                </thead>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <table id="body">
                <thead>
                  <tr>
                    <th>TIPO</th>
                    <th>DISCRIMINAÇÃO</th>
                    <th style="text-align: right">PRAZO</th>
                    <th style="text-align: right">VALOR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="lancamento in contracheque.lancamentos"
                    :key="lancamento.id">
                    <td>{{ lancamento.tipo }}</td>
                    <td>{{ lancamento.discriminacao }}</td>
                    <td style="text-align: right">{{ lancamento.prazo }}</td>
                    <td style="text-align: right">{{ lancamento.valor }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>BRUTO</th>
                    <th>DESCONTOS</th>
                    <th>LIQUIDO</th>
                  </tr>
                  <tr>
                    <td>{{ contracheque.bruto }}</td>
                    <td>{{ contracheque.descontos }}</td>
                    <td>{{ contracheque.liquido }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div
            class="row"
            style="
              width: 100%;
              margin: 21px 0px 0px 0px;
              padding: 10px;
              align-items: center;
              text-align: center;
              border: 1px solid black;
            ">
            <div class="col-2"></div>
            <div class="col-4">
              <h4><b>Valide aqui seu contracheque</b></h4>
              <img
                src="@/img/contrachequeIndicador.jpeg"
                alt="Image"
                style="width: 50%" />
              <h4><b>Aponte a câmera do seu celular e acesse o link.</b></h4>
            </div>
            <div class="col-4 d-flex justify-content-start">
              <qrcode-vue
                :value="qrCodeUrl"
                :size="qrCodeSize"
                level="H"></qrcode-vue>
            </div>
            <div class="col-2"></div>
            <div class="col-12">
              <h5><b>Ou acesse a validação atráves da url:</b></h5>
              <span
                ><b>{{ qrCodeUrl }}</b></span
              >
            </div>
          </div>
        </div>
      </template>
    </Card>
  </BlockUI>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import ContrachequeService from '@/service/ContrachequeService'

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      matricula: this.$route.params.matricula,
      cpf: this.$route.params.cpf,
      contrachequeId: this.$route.params.id,
      contracheque: {},
      showLoading: true,
      imagemSrc: null,
      qrCodeSize: 200,
      qrCodeUrl: '',
    }
  },

  created() {
    this.contrachequeService = new ContrachequeService(this.$http)
  },
  mounted() {
    this.carregarDados(this.cpf, this.matricula)
  },

  methods: {
    carregarDados(cpf, matricula) {
      this.contrachequeService
        .obterContracheque(this.contrachequeId, cpf, matricula)
        .then((res) => {
          this.contracheque = res
          this.carregarLogo()
          this.gerarQrcode()
          this.showLoading = false
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    carregarLogo() {
      const imagem = new Image()

      imagem.src = 'data:image/png;base64,' + this.contracheque.logo

      imagem.onload = () => {
        this.imagemSrc = imagem.src
      }
    },

    gerarQrcode() {
      if (window.location) {
        this.qrCodeUrl = `${window.location.host}/validacaoContracheque`

        this.qrCodeUrl += `?codigo=${this.removerMascara(
          this.contracheque.codigoCertificador,
        )}`
        this.qrCodeUrl += `&cpf=${this.removerMascara(this.contracheque.cpf)}`
        this.qrCodeUrl += `&bruto=${this.formatarValorBruto(
          this.contracheque.bruto,
        )}`
      }
    },

    removerMascara(data) {
      return data.replace(/\D/g, '')
    },

    formatarValorBruto(bruto) {
      bruto = bruto.replace('R$', '').replace(',', '.').replace('.', '')

      const valorFormatado = parseFloat(bruto)

      return Math.round(valorFormatado * 100)
    },

    showToastError(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style scoped>
.contracheque table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  padding: 10px;
}

.contracheque table thead {
  border: 1px solid black;
}

.contracheque table tfoot {
  border: 1px solid black;
}

.contracheque table#header thead tr#title th {
  padding-left: 0%;
  text-align: center;
  font-size: 18px;
}
.contracheque table#header thead tr th {
  padding-left: 10px;
  border: 1px solid black;
}

.contracheque table#body {
  margin-top: 10px;
}
.contracheque table#body thead tr th {
  padding-left: 10px;
}

.contracheque table#body tbody tr td {
  padding-left: 10px;
}

.contracheque table#body tfoot {
  text-align: center;
}
</style>
